import { createContext } from "react";

export const defaultCountry = {
  __typename: "CountryDisplay",
  code: "US",
  country: "United States of America",
};

export const defaultContext = {
  __typename: "Shop",
  countries: [],
  defaultCountry,
  displayGrossPrices: true,
};

export const ShopContext = createContext(
  defaultContext
);

ShopContext.displayName = "ShopContext";
